import { useMutation, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import FilingService from 'services/Filing';
import { AxiosError } from 'axios';
import {
  ContactDTO,
  CreateFilingDTO,
  CreateFilingFileDTO, CreateFilingFileInternalDTO,
  DashboardDataDTO, FilesNotificationDTO,
  FilingDTO,
  FilingQueryDTO,
  FilingsResponseDTO,
  FilingStateFileDTO,
  UpdateFilingDTO
} from 'dtos/filing';

export const useDashboardData = () => useQuery<DashboardDataDTO>(
  ['filing-dashboard'],
  () => FilingService.getDashboardData()
);

export const useFilings = (query: FilingQueryDTO) => useQuery<FilingsResponseDTO>(
  ['filings', query],
  () => FilingService.getAllFilings(query)
);

export const useFiling = (id: string) => useQuery<FilingDTO>(
  ['filing', id],
  () => FilingService.getFilingById(id)
);

export const useUpdateFiling = (
  options?: UseMutationOptions<FilingDTO, AxiosError, UpdateFilingDTO>
) => {
  const queryReport = useQueryClient();

  return useMutation(
    ['update-filing'],
    ({ id, ...data }: UpdateFilingDTO & { id: string}) => FilingService.updateFiling(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryReport.invalidateQueries(['filing', data.id]);
        await queryReport.invalidateQueries(['filings'])

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useCreateFiling = (
  options?: UseMutationOptions<FilingDTO, AxiosError, CreateFilingDTO>
) => {
  const queryReport = useQueryClient();

  return useMutation(
    ['create-filing'],
    (data: CreateFilingDTO) => FilingService.createFiling(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryReport.invalidateQueries(['filings'])

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useCreateUserFilingFile = (
  options?: UseMutationOptions<FilingStateFileDTO, AxiosError, CreateFilingFileDTO>
) => {
  const client = useQueryClient();

  return useMutation(
    ['create-filing-user-file'],
    (data: CreateFilingFileDTO) => FilingService.createFileByUser(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useCreateInternalFilingFile = (
  options?: UseMutationOptions<FilingStateFileDTO, AxiosError, CreateFilingFileInternalDTO>
) => {
  const client = useQueryClient();

  return useMutation(
    ['create-filing-internal-file'],
    (data: CreateFilingFileInternalDTO) => FilingService.createFilingFileByInternal(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useDeleteInternalFilingFile = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const client = useQueryClient();

  return useMutation(
    ['delete-filing-internal-file'],
    (id: string) => FilingService.deleteFilingFileByInternal(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useCreateVIAFilingFile = (
  options?: UseMutationOptions<FilingStateFileDTO, AxiosError, CreateFilingFileDTO>
) => {
  const client = useQueryClient();

  return useMutation(
    ['create-filing-via-file'],
    (data: CreateFilingFileDTO) => FilingService.createFilingFileByVIA(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useDeleteViaFilingFile = (
  options?: UseMutationOptions<void, AxiosError, string>
) => {
  const client = useQueryClient();

  return useMutation(
    ['delete-filing-internal-file'],
    (id: string) => FilingService.deleteFilingFileByVIA(id),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await client.refetchQueries(['filing']);

        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      }
    }
  );
};

export const useDownloadFilingUserFile = (
  options?: UseMutationOptions<{ url: string }, AxiosError, string>
) => {
  return useMutation(
    ['download-filing-user-file'],
    (id: string) => FilingService.downloadFilingUserFile(id), options
  );
};

export const useNotifyAboutFiles = (
  options?: UseMutationOptions<void, AxiosError, FilesNotificationDTO>
) => {
  return useMutation(
    ['notify-about-files'],
    (data: FilesNotificationDTO) => FilingService.sendNotificationAboutFiles(data), options
  );
};

export const useDownloadFilingInternalFile = (
  options?: UseMutationOptions<{ url: string }, AxiosError, string>
) => {
  return useMutation(
    ['download-filing-internal-file'],
    (id: string) => FilingService.downloadFilingInternalFile(id), options
  );
};

export const useDownloadFilingViaFile = (
  options?: UseMutationOptions<{ url: string }, AxiosError, string>
) => {
  return useMutation(
    ['download-filing-via-file'],
    (id: string) => FilingService.downloadFilingViaFile(id), options
  );
};

export const useContactFiling = (
  options?: UseMutationOptions<void, AxiosError, ContactDTO>
) => {
  return useMutation(
    ['contact-filing'],
    (data: ContactDTO) => FilingService.contact(data),
    options,
  );
};

