import React, { RefObject } from 'react';
import classNames from 'classnames';

import ClientLabel from './ClientLabel';

export type InputStatus = 'valid' | 'invalid';

export type Props = React.InputHTMLAttributes<unknown> & {
  useTextarea?: boolean;
  status?: InputStatus;
  statusMessage?: string;
  label?: string;
};

// eslint-disable-next-line react/display-name
export const ClientInput = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement | null,
  Props
  >((props, ref) => {
  const {
    useTextarea,
    className,
    status,
    statusMessage,
    label,
    name,
    value,
    type = 'text',
    required,
    ...restProps
  } = props;
  const classes = classNames('w-full', className);
  
  const inputClasses = 'w-full placeholder:text-white/30 bg-white/20 border-none rounded-md p-2';
  
  return (
    <div className={classes}>
      {label && (
        <ClientLabel className='block mb-1' text={label + (required ? '' : '*')} htmlFor={name} invalid={status === 'invalid'} />
      )}
      {useTextarea ? (
        <textarea
          required={required}
          name={name}
          id={name}
          value={value}
          className={inputClasses}
          ref={ref as RefObject<HTMLTextAreaElement>}
          {...restProps}
        />
      ) : (
        <input
          required={required}
          name={name}
          id={name}
          value={value}
          type={type}
          className={inputClasses}
          ref={ref as RefObject<HTMLInputElement>}
          {...restProps}
        />
      )}
      {status === 'invalid' ? (
        <p className='text-red-500 mt-1'>{statusMessage}</p>
      ) : null}
    </div>
  );
});
