import React from 'react';
import cx from 'classnames';

import { ClientHr } from 'components/ClientHr';

import styles from './ClientTitle.module.scss';

interface Props {
  className?: string;
  vertical?: boolean;
  inverted?: boolean;
  skipMargin?: boolean;
  children: React.ReactNode;
}

export const ClientTitle = ({ children, className, vertical, skipMargin, inverted }: Props) => {
  const classes = cx(
    'tracking-[.3em] text-lg leading-wide',
    !vertical && 'text-center md:text-left',
    styles.title,
    vertical && styles.vertical,
    inverted && styles.inverted,
    skipMargin && styles.skipMargin,
    className
  );

  return (
    <>
      <h3 className={classes}>{children}</h3>
      {vertical && <ClientHr className='mx-auto hidden md:block my-2' inverted={inverted}/>}
    </>
  );
}
