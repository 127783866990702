import React from 'react';
import cx from 'classnames';

import styles from './ClientHr.module.scss';

interface Props {
  className?: string;
  block?: boolean;
  inverted?: boolean;
}

export const ClientHr = ({ className, block }: Props) => {
  return (
    <hr className={cx(styles.breaker, block && styles.block, className)}/>
  );
}
