import React from 'react';
import classNames from 'classnames';

type Props = React.LabelHTMLAttributes<unknown> & {
  text: string;
  invalid?: boolean;
  labelClass?: string;
};

const Label: React.FC<Props> = (props) => {
  const { labelClass, invalid, ...restProps } = props;
  const classes = classNames(props.labelClass, {
    '': invalid,
  });

  return (
    <label className={classes} {...restProps}>
      {props.text}
    </label>
  );
};
export default Label;
