import React from 'react';
import cx from 'classnames';

import { ClientTitle } from 'components/ClientTitle';

interface Props {
  title: string;
  className?: string;
  titleClassName?: string;
  vertical?: boolean;
  inverted?: boolean;
  id?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const ClientSection = ({ title, children, className, vertical, titleClassName, inverted, style, id }: Props) => {
  return (
    <section className={cx('container', className)} style={style} id={id}>
      <ClientTitle vertical={vertical} className={titleClassName} inverted={inverted}>
        {title.toUpperCase()}
      </ClientTitle>
      {children}
    </section>
  );
}
