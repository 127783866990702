import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { ClientInput } from 'components/ClientInput';
import { Button } from 'antd';
import { useContactFiling } from 'queries/useFiling';
import { ContactDTO } from 'dtos';

const schema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  message: yup.string().required('Message is required'),
});

interface Props {
  className?: string;
}

export const ContactForm = ({}: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<ContactDTO>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      message: '',
    }
  });
  
  const { mutate, isLoading, error } = useContactFiling({
    onSuccess: () => {
      setShowSuccess(true);
      reset();
      
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    }
  });
  
  const handleSend = async (payload: ContactDTO) => {
    await mutate(payload);
  };
  
  return (
    <div className="bg-white/20 p-8 rounded-md">
      <form onSubmitCapture={handleSubmit(handleSend)} autoComplete="on" className='flex flex-col gap-4'>
        <div className='flex flex-col md:flex-row gap-4'>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <ClientInput
                required
                label="First name"
                placeholder="John"
                onChange={field.onChange}
                value={field.value}
                status={errors.firstName ? 'invalid' : 'valid'}
                statusMessage={errors.firstName?.message}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <ClientInput
                required
                label="Last name"
                placeholder="Doe"
                onChange={field.onChange}
                value={field.value}
                status={errors.lastName ? 'invalid' : 'valid'}
                statusMessage={errors.lastName?.message}
              />
            )}
          />
        </div>

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <ClientInput
              required
              label="Email"
              placeholder="john@doe.com"
              onChange={field.onChange}
              value={field.value}
              autoComplete="email"
              status={errors.email ? 'invalid' : 'valid'}
              statusMessage={errors.email?.message}
            />
          )}
        />
        
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <ClientInput
              required
              useTextarea
              className="w-full"
              label="Message"
              placeholder="Write your message here"
              onChange={field.onChange}
              value={field.value}
              autoComplete="password"
              status={errors.message ? 'invalid' : 'valid'}
              statusMessage={errors.message?.message}
            />
          )}
        />
        
        <div className="text-right">
          <Button htmlType="submit" loading={isLoading} type='primary'>
            {isLoading ? 'Sending...' : 'Send email'}
          </Button>
  
          {error && <p className="text-red-500 mt-4">{error.message}</p>}
          {showSuccess && <p className="text-green-500 mt-4">Message sent successfully!</p>}
        </div>
      </form>
    </div>
  );
};
