import RequestService from 'services/Request';
import { pickBy, isUndefined, negate } from 'lodash';
import qs from 'query-string';
import {
  ContactDTO,
  CreateFilingDTO,
  CreateFilingFileDTO,
  CreateFilingFileInternalDTO, CreateFilingStateFileDTO,
  DashboardDataDTO, FilesNotificationDTO, FilingDTO,
  FilingQueryDTO,
  FilingsResponseDTO,
  FilingStateDTO,
  FilingStateFileDTO,
  FilingStateQueryDTO, UserStateRatesQueryDTO,
  StateRatesDTO,
  UpdateFilingDTO,
  UpdateFilingStateDTO,
  UpdateStateRatesDTO,
  UploadAdminFileDTO, UploadStateFileDTO,
  UploadUserFileDTO, StateSettingsDTO, UpdateStateSettingsDTO
} from 'dtos/filing';
import { FileUploadResponseDTO } from 'dtos';
import fileDownload from 'js-file-download';

class _FilingService {
  contact(data: ContactDTO): Promise<void> {
    return RequestService.client.post<void>(`filing/contact`, data)
      .then((res) => res.data);
  }

  getAllFilings(query: FilingQueryDTO): Promise<FilingsResponseDTO> {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<FilingsResponseDTO>(`filing?${params}`)
      .then((res) => res.data);
  }

  getFilingById(id: string): Promise<FilingDTO> {
    return RequestService.client.get<FilingDTO>(`filing/${id}`)
      .then((res) => res.data);
  }

  createFiling(data: CreateFilingDTO): Promise<FilingDTO> {
    return RequestService.client.post<FilingDTO>(`filing`, data)
      .then((res) => res.data);
  }

  updateFiling(id: string, data: UpdateFilingDTO): Promise<FilingDTO> {
    return RequestService.client.patch<FilingDTO>(`filing/${id}`, data)
      .then((res) => res.data);
  }

  createFileByUser(data: CreateFilingFileDTO): Promise<FilingStateFileDTO> {
    return RequestService.client.post<FilingStateFileDTO>(`filing/file/user`, data)
      .then((res) => res.data);
  }

  createFilingFileByVIA(data: CreateFilingFileDTO): Promise<FilingStateFileDTO> {
    return RequestService.client.post<FilingStateFileDTO>(`filing/file/via`, data)
      .then((res) => res.data);
  }

  createFilingFileByInternal(data: CreateFilingFileInternalDTO): Promise<FilingStateFileDTO> {
    return RequestService.client.post<FilingStateFileDTO>(`filing/file/internal`, data)
      .then((res) => res.data);
  }

  deleteFilingFileByVIA(id: string): Promise<void> {
    return RequestService.client.delete<void>(`filing/file/via/${id}`)
    .then((res) => res.data);
  }

  deleteFilingFileByInternal(id: string): Promise<void> {
    return RequestService.client.delete<void>(`filing/file/internal/${id}`)
    .then((res) => res.data);
  }

  uploadFilingFileByAdmin(data: UploadAdminFileDTO): Promise<FileUploadResponseDTO> {
    return RequestService.client.post<FileUploadResponseDTO>(`filing/upload/admin`, data)
      .then((res) => res.data);
  }

  uploadFilingFileByUser(data: UploadUserFileDTO): Promise<FileUploadResponseDTO> {
    return RequestService.client.post<FileUploadResponseDTO>(`filing/upload/user`, data)
      .then((res) => res.data);
  }

  createFilingStateFile(data: CreateFilingStateFileDTO): Promise<FilingStateFileDTO> {
    return RequestService.client.post<FilingStateFileDTO>(`filing-state/file`, data)
      .then((res) => res.data);
  }

  sendNotificationAboutFiles(data: FilesNotificationDTO): Promise<void> {
    return RequestService.client.post<void>(`filing/send-notification`, data)
      .then((res) => res.data);
  }

  deleteFilingStateFile(id: string): Promise<void> {
    return RequestService.client.delete<void>(`filing-state/file/${id}`)
      .then((res) => res.data);
  }

  async downloadFilingStateFile(id: string): Promise<{ url: string }> {
    const { url } = await RequestService.client.post<{ url: string }>(`/filing-state/file/${id}/generate-download-url`)
      .then((res) => res.data);

    const file = await RequestService.client.get<Blob>(url, { responseType: 'blob', }).then((res) => res.data);

    let fileName = url.split('/').pop()?.split('?')[0] || 'state-document.pdf';
    fileName = fileName.substring(14);
    fileDownload(file, fileName);

    return { url };
  }

  async downloadFilingUserFile(id: string): Promise<{ url: string }> {
    const { url } = await RequestService.client.post<{ url: string }>(`/filing/file/user/${id}/generate-download-url`)
      .then((res) => res.data);

    const file = await RequestService.client.get<Blob>(url, { responseType: 'blob', }).then((res) => res.data);

    let fileName = url.split('/').pop()?.split('?')[0] || 'user-document.pdf';
    fileDownload(file, fileName);

    return { url };
  }

  async downloadFilingInternalFile(id: string): Promise<{ url: string }> {
    const { url } = await RequestService.client.post<{ url: string }>(`/filing/file/internal/${id}/generate-download-url`)
      .then((res) => res.data);

    const file = await RequestService.client.get<Blob>(url, { responseType: 'blob', }).then((res) => res.data);

    let fileName = url.split('/').pop()?.split('?')[0] || 'internal-document.pdf';
    fileName = fileName.substring(14);
    fileDownload(file, fileName);

    return { url };
  }

  async downloadFilingViaFile(id: string): Promise<{ url: string }> {
    const { url } = await RequestService.client.post<{ url: string }>(`/filing/file/via/${id}/generate-download-url`)
      .then((res) => res.data);

    const file = await RequestService.client.get<Blob>(url, { responseType: 'blob', }).then((res) => res.data);

    let fileName = url.split('/').pop()?.split('?')[0] || 'via-document.pdf';
    fileName = fileName.substring(14);
    fileDownload(file, fileName);

    return { url };
  }

  uploadFileStateFile(data: UploadStateFileDTO): Promise<FileUploadResponseDTO> {
    return RequestService.client.post<FileUploadResponseDTO>(`filing-state/upload`, data)
      .then((res) => res.data);
  }

  getDashboardData(): Promise<DashboardDataDTO> {
    return RequestService.client.get<DashboardDataDTO>(`filing/dashboard`)
      .then((res) => res.data);
  }

  getAllFilingStates(query: FilingStateQueryDTO): Promise<FilingStateDTO[]> {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<FilingStateDTO[]>(`filing-state?${params}`)
      .then((res) => res.data);
  }

  getFilingStateRatesForUser(abbr: string, query: UserStateRatesQueryDTO, type: 'nb' | 'ed'): Promise<StateRatesDTO> {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<StateRatesDTO>(`filing-state/state-rates/${abbr}/${type}?${params}`)
      .then((res) => res.data);
  }

  getFilingStateSettingsForUser(abbr: string, query: UserStateRatesQueryDTO): Promise<StateSettingsDTO> {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<StateSettingsDTO>(`filing-state/state-rates/${abbr}?${params}`)
      .then((res) => res.data);
  }

  getFilingStateById(id: string): Promise<FilingStateDTO> {
    return RequestService.client.get<FilingStateDTO>(`filing-state/${id}`)
      .then((res) => res.data);
  }

  getFilingStateByAbbr(abbr: string): Promise<FilingStateDTO> {
    return RequestService.client.get<FilingStateDTO>(`filing-state/abbr/${abbr}`)
      .then((res) => res.data);
  }

  updateFilingStateById(id: string, data: UpdateFilingStateDTO): Promise<FilingStateDTO> {
    return RequestService.client.patch<FilingStateDTO>(`filing-state/${id}`, data)
      .then((res) => res.data);
  }

  updateFilingStateRates(filingStateId: string, type: 'nb' | 'ed', data: UpdateStateRatesDTO): Promise<StateRatesDTO> {
    return RequestService.client.patch<StateRatesDTO>(
      `filing-state/${filingStateId}/state-rates/${type}`,
      pickBy(data, negate(isUndefined)) // filter out undefined values, but preserve null values
    )
      .then((res) => res.data);
  }

  updateFilingStateSettings(filingStateId: string, data: UpdateStateSettingsDTO): Promise<StateSettingsDTO> {
    return RequestService.client.patch<StateSettingsDTO>(
      `filing-state/${filingStateId}/state-settings`,
      pickBy(data, negate(isUndefined)) // filter out undefined values, but preserve null values
    )
      .then((res) => res.data);
  }
}

const FilingService = new _FilingService();

export default FilingService;
